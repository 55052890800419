import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import Layout from 'src/components/Layout'
import SEO from 'src/components/seo'
import Button from 'src/components/Button'
import 'src/styles/video_gallery.scss'
import SectionTitle from 'src/components/SectionTitle'
import Popup from 'reactjs-popup'
import Helmet from 'react-helmet'

export default ({ data }) => {
  const locale = data.allRestApiApiVideoGallery.edges[0].node.locale
  const video_gallery_content =
    data.allRestApiApiVideoGallery.edges[0].node.content

  const [openVideoPopup, setOpenVideoPopup] = useState(false)
  const [checkbox, setCheckBox] = useState('')
  const [password, setPassword] = useState('')
  const [error, showError] = useState('')
  const [showVideoGallery, setShowVideoGallery] = useState(false)
  // const forScroll = document.getElementById('for_scroll')

  const handleChange = e => {
    // setCheckBox(e.target.checked)
    if (e.target.checked == true) {
      setCheckBox('active')
    } else {
      setCheckBox('')
    }
  }

  useEffect(() => {
    const canView = window.localStorage.getItem('can_view_video_gallery')
    if (canView === 'true') {
      setShowVideoGallery(true)
    }
  }, [])

  const handleSubmit = () => {
    if (password === 'PRUMDRT2020') {
      window.localStorage.setItem('can_view_video_gallery', 'true')
      setShowVideoGallery(true)
    } else {
      showError('show')
      document.getElementById('for_scroll').scrollIntoView({
        behavior: 'smooth'
      })
    }
  }
  const renderPasswordSection = () => (
    <div
      className="login-section video-gallery-section"
      style={{
        backgroundImage: `url(${video_gallery_content.password_section_background})`
      }}
    >
      <div className="form-login" id="for_scroll">
        <div className="please-key-password">
          {video_gallery_content.please_key_in_password}
        </div>
        <div className="form-group form-group-password">
          <label>{video_gallery_content.password}</label>
          <div className="fields">
            <input
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <div className={`errors ${error}`}>密碼錯誤</div>
          </div>
        </div>
        <div className="container-checkbox" id="click_checkbox">
          {video_gallery_content.agree_and_consent}
          <input
            className={`pru-checkbox ${checkbox}`}
            type="checkbox"
            onChange={handleChange}
          />
          <span className="checkmark"></span>
        </div>
        <div className="text-notice">
          <p className="you-are-not-text">
            {video_gallery_content.you_are_not_allowed_to}
          </p>
          <ol>
            {video_gallery_content.you_are_not_allowed_to_contents.map(item => (
              <li className="normal-text">{parse(item)}</li>
            ))}
          </ol>
        </div>
        <div className="login-button video-gallery-enter-button">
          <Button
            style={{ minWidth: 200 }}
            title={video_gallery_content.enter}
            onClick={handleSubmit}
            active={checkbox}
          ></Button>
        </div>
      </div>
    </div>
  )

  const renderSpeakersVideo = (
    { data, title, backgroundImage },
    isPruSpeakers = false
  ) => (
    <div
      className="speakers-video-gallery-section"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="speakers-title-wrapper">
        <SectionTitle hasIcon={false} title={title}></SectionTitle>
      </div>
      <div className="speakers-video-list">
        {data.map((item, index) => (
          <div
            key={index}
            className={`speaker-video-item ${isPruSpeakers ? 'pru-item' : ''}`}
          >
            <Helmet>
              <script
                src={`https://fast.wistia.com/embed/medias/${item.video_url}.jsonp`}
                type="text/javascript"
                async
              />
            </Helmet>
            <span
              className={`wistia_embed wistia_async_${item.video_url} popover=true popoverContent=link`}
              style={{ display: 'inline', position: 'relative' }}
            >
              {item.video_url ? (
                <a href="#">
                  <img src={item.thumbnail_url} alt="" />
                </a>
              ) : (
                <div className="video-mask-container">
                  <img src={item.thumbnail_url} alt="" />
                  <div className="video-mask">
                    <div className="mask-text">即將推出,敬請期待</div>
                  </div>
                </div>
              )}
            </span>
            <div className="video-topic">{item.topic}</div>
            <div className="video-speaker-name">{item.speaker}</div>
          </div>
        ))}
      </div>
      {isPruSpeakers && (
        <div className="speakers-video-gallery-notes">
          {parse(video_gallery_content.video_gallery_note)}
        </div>
      )}
    </div>
  )

  const renderVideoPopup = () => (
    <Popup
      open={openVideoPopup}
      closeOnDocumentClick
      onClose={() => setOpenVideoPopup(false)}
      contentStyle={{
        width: '95%',
        maxWidth: 1732,
        borderRadius: 12
      }}
      overlayStyle={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
      className="run-video-popup"
      modal
      lockScroll
      repositionOnResize
    >
      <div className="run-video-popup">
        <div className="speaker-popup-container">
          <div className="videoWrapper"></div>
        </div>
      </div>
    </Popup>
  )

  return (
    <Layout locale={'zh'}>
      <SEO title="Video Gallery" />
      <Helmet>
        <script
          src={'https://fast.wistia.com/assets/external/E-v1.js'}
          type="text/javascript"
        />
      </Helmet>
      <div className="home-banner-wrapper">
        <img src={video_gallery_content.video_gallery_banner} alt="" />
        <div className="blend-line"></div>
      </div>
      <div className={`video-gallery-page ${locale}`}>
        {showVideoGallery ? (
          <>
            {renderSpeakersVideo({
              data: video_gallery_content.speakers,
              title: video_gallery_content.speakers_title,
              backgroundImage:
                video_gallery_content.keynotes_speakers_background
            })}
            {renderSpeakersVideo(
              {
                data: video_gallery_content.prudential_speakers,
                title: video_gallery_content.prudential_speakers_title,
                backgroundImage: video_gallery_content.pru_speaks_background
              },
              true
            )}
            {renderVideoPopup()}
          </>
        ) : (
          renderPasswordSection()
        )}
      </div>
    </Layout>
  )
}

export const GatsbyQuery = graphql`
  {
    allRestApiApiVideoGallery(filter: { locale: { eq: "zh" } }) {
      edges {
        node {
          locale
          content {
            video_gallery_banner
            please_key_in_password
            password
            agree_and_consent
            you_are_not_allowed_to
            enter
            video_gallery_note
            speakers_title
            prudential_speakers_title
            keynotes_speakers_background
            password_section_background
            pru_speaks_background
            you_are_not_allowed_to_contents
            speakers {
              id
              topic
              speaker
              thumbnail_url
              video_url
            }
            prudential_speakers {
              id
              topic
              speaker
              thumbnail_url
              video_url
            }
          }
        }
      }
    }
  }
`
